/*prettier-ignore*/
#profilePicture {
  display: block;
  margin: 10px auto;
  border-radius: 99em;
  max-width: 180px;
  box-shadow:
  -25px 0px 40px rgba(57, 165, 207, 0.35),
  25px 0px 40px rgba(50, 223, 182,0.25);
}

h1 {
  font-size: 4em;
  letter-spacing: 1.5px;
  margin: 30px 0px -10px 0px;
  white-space: nowrap !important;
  transition: 0.2s all ease;
}

h1:hover em {
  transform: rotate(4deg) scale(0.88) !important;
  background-color: rgba(61, 194, 246, 0.1);
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4) !important;
  border-radius: 0.15em;
  transition: 1s all ease;
}

em {
  border-radius: 0.05em;
  background-color: rgba(61, 194, 246, 0.001);
  padding: 7px 9px;
  margin-left: -10px;
  display: inline-block;
  transform: rotate(0deg) !important;
  font-style: normal;
  transition: 0.2s all ease;
}

h2 {
  font-size: 2em;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

#available {
  background: rgba(50, 223, 102, 0.65);
  text-transform: uppercase;
  padding: 4px 18px 4px 12px;
  border-radius: 99em;
  letter-spacing: 1px;
  color: rgb(240, 255, 245) !important;
  border: 3px solid rgba(255, 255, 255, 0.1);
  font-weight: bold;
  margin: 5px;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px rgba(43, 255, 107, 0.15);
  animation: glowingGreen 1.5s linear infinite;
}

@keyframes glowingGreen {
  0% {
    box-shadow: 0px 0px 0px rgba(43, 255, 107, 0);
    opacity: 0.9;
  }
  50% {
    box-shadow: 0px 0px 20px rgba(0, 184, 55, 0.9);
    opacity: 1;
  }
  100% {
    box-shadow: 0px 0px 40px rgba(43, 255, 107, 0);
    opacity: 0.9;
  }
}

#unavailable {
  background: rgba(223, 50, 50, 0.445);
  text-transform: uppercase;
  padding: 4px 18px 4px 12px;
  border-radius: 99em;
  letter-spacing: 1px;
  color: rgb(255, 245, 245) !important;
  border: 3px solid rgba(255, 255, 255, 0.1);
  font-weight: bold;
  margin: 5px;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px rgba(255, 43, 43, 0.15);
}

.fancyHr {
  margin: 20px 0px 50px 0px;
  border: none;
  height: 2px;
  border-radius: 99em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05)
  );
}

.card {
  margin: 15px auto;
  padding: 3em 2em;
  color: white;
  border-radius: 0.5em;
  max-width: 850px;
  min-width: 400px;
  cursor: default;
}

.special {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: hsla(197, 61%, 50%, 0.15);
  transition: 0.2s all ease;
}

.special:hover {
  transition: 0.2s all ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
}

.titleCardParagraphs {
  font-size: 1.5em;
  line-height: 1.4;
}
