.talkTitle {
  margin-bottom: 0px !important;
}
.talkLocation {
  margin: 5px 0px 20px 0px;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.5);
}
.talkDate {
}
