@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&family=Roboto:wght@400;700&display=swap);
/*prettier-ignore*/
#TitleCard_profilePicture__3KflI {
  display: block;
  margin: 10px auto;
  border-radius: 99em;
  max-width: 180px;
  box-shadow:
  -25px 0px 40px rgba(57, 165, 207, 0.35),
  25px 0px 40px rgba(50, 223, 182,0.25);
}

h1 {
  font-size: 4em;
  letter-spacing: 1.5px;
  margin: 30px 0px -10px 0px;
  white-space: nowrap !important;
  transition: 0.2s all ease;
}

h1:hover em {
  -webkit-transform: rotate(4deg) scale(0.88) !important;
          transform: rotate(4deg) scale(0.88) !important;
  background-color: rgba(61, 194, 246, 0.1);
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4) !important;
  border-radius: 0.15em;
  transition: 1s all ease;
}

em {
  border-radius: 0.05em;
  background-color: rgba(61, 194, 246, 0.001);
  padding: 7px 9px;
  margin-left: -10px;
  display: inline-block;
  -webkit-transform: rotate(0deg) !important;
          transform: rotate(0deg) !important;
  font-style: normal;
  transition: 0.2s all ease;
}

h2 {
  font-size: 2em;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

#TitleCard_available__3oquo {
  background: rgba(50, 223, 102, 0.65);
  text-transform: uppercase;
  padding: 4px 18px 4px 12px;
  border-radius: 99em;
  letter-spacing: 1px;
  color: rgb(240, 255, 245) !important;
  border: 3px solid rgba(255, 255, 255, 0.1);
  font-weight: bold;
  margin: 5px;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px rgba(43, 255, 107, 0.15);
  -webkit-animation: TitleCard_glowingGreen__PvQCN 1.5s linear infinite;
          animation: TitleCard_glowingGreen__PvQCN 1.5s linear infinite;
}

@-webkit-keyframes TitleCard_glowingGreen__PvQCN {
  0% {
    box-shadow: 0px 0px 0px rgba(43, 255, 107, 0);
    opacity: 0.9;
  }
  50% {
    box-shadow: 0px 0px 20px rgba(0, 184, 55, 0.9);
    opacity: 1;
  }
  100% {
    box-shadow: 0px 0px 40px rgba(43, 255, 107, 0);
    opacity: 0.9;
  }
}

@keyframes TitleCard_glowingGreen__PvQCN {
  0% {
    box-shadow: 0px 0px 0px rgba(43, 255, 107, 0);
    opacity: 0.9;
  }
  50% {
    box-shadow: 0px 0px 20px rgba(0, 184, 55, 0.9);
    opacity: 1;
  }
  100% {
    box-shadow: 0px 0px 40px rgba(43, 255, 107, 0);
    opacity: 0.9;
  }
}

#TitleCard_unavailable__2ny-w {
  background: rgba(223, 50, 50, 0.445);
  text-transform: uppercase;
  padding: 4px 18px 4px 12px;
  border-radius: 99em;
  letter-spacing: 1px;
  color: rgb(255, 245, 245) !important;
  border: 3px solid rgba(255, 255, 255, 0.1);
  font-weight: bold;
  margin: 5px;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px rgba(255, 43, 43, 0.15);
}

.TitleCard_fancyHr__39Qf4 {
  margin: 20px 0px 50px 0px;
  border: none;
  height: 2px;
  border-radius: 99em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05)
  );
}

.TitleCard_card__34r_W {
  margin: 15px auto;
  padding: 3em 2em;
  color: white;
  border-radius: 0.5em;
  max-width: 850px;
  min-width: 400px;
  cursor: default;
}

.TitleCard_special__1zxKU {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: hsla(197, 61%, 50%, 0.15);
  transition: 0.2s all ease;
}

.TitleCard_special__1zxKU:hover {
  transition: 0.2s all ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
}

.TitleCard_titleCardParagraphs__2Sq4Q {
  font-size: 1.5em;
  line-height: 1.4;
}

.Card_card__1eE8R {
  margin: 20px auto;
  padding: 30px;
  background-color: rgba(40, 50, 55, 0.6);
  color: white;
  border-radius: 0.5em;
  max-width: 750px;
  cursor: default;
  transition: 0.2s all ease;
}

/* .card:hover {
  transition: 0.2s all ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
} */

h3 {
  letter-spacing: 0.9px;
  margin: 3px 0px;
  font-size: 2em;
}

.Card_fancyHr__2tc3c {
  border: none;
  height: 2px;
  border-radius: 99em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05)
  );
}

.VibeCheck_card__1Jvvw {
  margin: 15px auto;
  padding: 1.5em 2em;
  background-color: rgba(40, 50, 55, 0.6);
  color: white;
  border-radius: 0.5em;
  max-width: 750px;
  min-width: 400px;
  cursor: default;
}

.VibeCheck_card__1Jvvw:hover {
  transition: 0.2s all ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}

#VibeCheck_vibeCheckEmoji__2zqx3 {
  display: inline-block;
  -webkit-transform: rotate(-25deg) !important;
          transform: rotate(-25deg) !important;
}

.VibeCheck_fancyHr__1ID2o {
  border: none;
  height: 2px;
  border-radius: 99em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05)
  );
}

.VibeCheck_imageWrapper__RWcZq {
  text-align: center;
  width: 400px;
  margin: 0 auto;
}

img {
  margin: 5px;
}

#VibeCheck_codewarsImgEmbed__1M8Bo {
  width: 400px !important;
  height: 40px !important;
}

#VibeCheck_psIQimageEmbed__MoN5x {
  width: 350px;
  border-radius: 0.5em;
  margin-top: 15px;
}

#VibeCheck_codewarsImgEmbed__1M8Bo,
#VibeCheck_psIQimageEmbed__MoN5x {
  transition: 0.2s all ease;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}

#VibeCheck_codewarsImgEmbed__1M8Bo:hover,
#VibeCheck_psIQimageEmbed__MoN5x:hover {
  transition: 0.2s all ease;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.TestimonialCard_testimonialCard__1fTbi {
    display: flex;
}

.TestimonialCard_testimonialName__2qJbE {
    text-decoration: none;
}

.TestimonialCard_flexItem__2bhCF {
    padding: 12px;
}

.TestimonialCard_testimonialImg__9ZmSm {
    height: 125px !important;
    width: 125px !important;
    border-radius: 999em;
    pointer-events: none;
}

.TestimonialCard_testimonialJobTitle__3jCHG {
    margin: 0;
    text-transform: uppercase;
    font-size: 1em !important;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.5);
}

.TestimonialCard_testimonialText__3DYrB {
    line-height: 1.5rem;
    text-align: justify;
    padding-right: 10px;
    font-size: 1.1em;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.85);
}

.TestimonialCard_testimonialText__3DYrB b {
    color: rgba(255, 255, 255, 1);
}

.TestimonialCard_card__2TmIR {
    margin: 12px auto auto;
    padding: 15px;
    background-color: hsla(200, 16%, 25%, 0.6);
    color: white;
    border-radius: 0.5em;
    max-width: 750px;
    min-width: 400px;
    cursor: default;
    text-align: left;
    opacity: 0.9;
    transition: 0.2s all ease;
}

.TestimonialCard_card__2TmIR:hover {
    transition: 0.2s all ease;
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.03);
}

.ProjectCard_card__3-cvU {
    margin: 15px auto;
    padding: 15px;
    background-color: hsla(200, 16%, 25%, 0.6);
    color: white;
    border-radius: 0.5em;
    max-width: 750px;
    min-width: 400px;
    cursor: default;
    text-align: left;
    opacity: 0.9;
    transition: 0.2s all ease;
}

h4 {
    letter-spacing: 0.9px;
    font-size: 1.75em;
    margin: 3px 0;
}

.ProjectCard_projectThumbnail__3o-Ht {
    height: 210px;
    float: left;
    border-radius: 0.5em;
}

.ProjectCard_projectThumbnailLink__2NiVv {
    opacity: 0.8;
    display: inline-block;
    margin: 15px !important;
    padding: 0 !important;
    border: none !important;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    transition: all 0.5s ease !important;
}

.ProjectCard_projectThumbnailLink__2NiVv:hover {
    opacity: 1;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.25) !important;
}

.ProjectCard_descriptionArea__9GKhp {
    padding: 0.5em 1em;
    display: inline-block;
}

.ProjectCard_descriptionArea__9GKhp p {
    width: 90%;
    font-size: 1.15em;
    letter-spacing: 0.5px;
    line-height: 1.25em;
}

.ProjectCard_techStackLogo__1GOZe {
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    margin: 3px;
    width: 2em;
    height: 2em;
    border-radius: 0.5em;
}

.ProjectCard_linksArea__2NUWb {
    margin: 25px 0;
    line-height: 3.5em;
    white-space: nowrap;
}

.ProjectCard_card__3-cvU a {
    font-family: "Raleway", sans-serif;
    color: white;
    background: none;
    font-size: 1em;
    margin: 0 5px;
    -webkit-text-decoration-color: rgba(255, 255, 255, 0);
            text-decoration-color: rgba(255, 255, 255, 0);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid white;
}

.ProjectCard_card__3-cvU a:hover {
    transition: 0.2s all ease;
    background: rgba(255, 255, 255, 0.3);
    -webkit-text-decoration-color: rgba(255, 255, 255, 1);
            text-decoration-color: rgba(255, 255, 255, 1);
}

.MoreComingSoon_MoreComingSoonContainer__24W8P {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  background: hsla(197, 61%, 50%, 0.1);
  border-radius: 0.5em;
}

.MoreComingSoon_MoreComingSoonText__2zSLx {
  color: hsla(197, 61%, 50%, 1);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.25em;
}

.Talks_talkTitle__2L8Ts {
  margin-bottom: 0px !important;
}
.Talks_talkLocation__MKwSn {
  margin: 5px 0px 20px 0px;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.5);
}
.Talks_talkDate__Aqgl4 {
}

.Hobbies_hobbyTitle__3Cmxn {
  margin-bottom: 1rem;
}

/*ul*/
.Hobbies_hobbyList__1nN45 li::before {
  content: "⇾";
  margin-right: 1.5rem;
  opacity: 0.3;
}

.Hobbies_hobbyList__1nN45 li {
  margin: 0.75rem -2rem;
  list-style-type: none;
}
.Hobbies_hobbyList__1nN45 li a {
  color: #39a4cf;
  -webkit-text-decoration-color: rgba(47, 88, 104, 0.8);
          text-decoration-color: rgba(47, 88, 104, 0.8);
}

.Hobbies_hobbyList__1nN45 li a:hover {
  color: #23c1ff !important;
  -webkit-text-decoration-color: rgba(47, 88, 104, 1);
          text-decoration-color: rgba(47, 88, 104, 1);
}

.Hobbies_hobbyList__1nN45 li a:visited {
  color: #39a4cf;
}

.ContactDetails_contactText__CEYBR {
    text-align: center;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 0.75);
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 50px auto 0;
}

.ContactDetails_contactButtonContainer__NigZt {
    display: block;
    text-align: center;
    padding: 35px;
    margin-bottom: 10rem;
    color: white;
    border-radius: 0.5rem;
    z-index: 999;
    opacity: 1;
    line-height: 4rem;
}

.ContactDetails_contactButtonContainer__NigZt a {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    color: rgba(40, 49, 53, 1);
    background: white !important;
    font-size: 1.1em;
    margin: 0 0.5em;
    -webkit-text-decoration-color: rgba(255, 255, 255, 0);
            text-decoration-color: rgba(255, 255, 255, 0);
    padding: 0.85rem 1.25rem;
    border-radius: 0.5rem;
    border: 1px solid white;
}

.ContactDetails_contactButtonContainer__NigZt a:hover {
    transition: 0.2s all ease;
    background: rgba(255, 255, 255, 0.1);
    text-decoration: none !important;
    z-index: 10;
}

.ContactDetails_contactButtonLogo__Pu9ji {
    width: 1.2em;
    height: 1.2em;
    aspect-ratio: 1/1;
    display: inline-block;
    padding: 0 0.2em;
    margin: -0.175em auto;
    fill: #ffffff;
}

.ContactDetails_hide__1XzJD {
    opacity: 0;
}

.ContactDetails_fancyHr__3tc7c {
    border: none;
    height: 2px;
    border-radius: 99em;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.05)
    );
}

.ContactDetails_rainbowBackground__1zTuf {
    position: fixed;
    width: 500px;
    height: 500px;
    bottom: -35%;
    left: calc(50% - 7rem);
    color: black;
    margin-top: -12.5%; /* Negative half of height. */
    margin-left: -12.5%; /* Negative half of width. */
    border-radius: 999rem;
    background: red;
    z-index: -1;
    background: linear-gradient(
            45deg,
            #eb5f1b,
            #c940c5,
            #2180e3,
            #25c7d0,
            #29cbaa,
            #57c643,
            #efc022,
            #ff9227,
            #eb5f1b
    );
    -webkit-filter: blur(80px);
            filter: blur(80px);
    opacity: 0.3;
    -webkit-animation: ContactDetails_shiftRainbow__30CsE 10s linear infinite;
            animation: ContactDetails_shiftRainbow__30CsE 10s linear infinite;
}

@-webkit-keyframes ContactDetails_shiftRainbow__30CsE {
    0% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    50% {
        -webkit-transform: rotate(180deg) scale(1, 0.75);
                transform: rotate(180deg) scale(1, 0.75);
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes ContactDetails_shiftRainbow__30CsE {
    0% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    50% {
        -webkit-transform: rotate(180deg) scale(1, 0.75);
                transform: rotate(180deg) scale(1, 0.75);
    }
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

html {
    min-height: 100%;
}

body {
    background: linear-gradient(90deg, #2a3035, #171a1d);
    background-size: calc(100vw) 100%;
    height: 100%;
    width: calc(100% - 10px);
    overflow-x: hidden;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

h4 {
    font-size: 1.35em;
}

main {
    padding: 20px;
    font-family: "Roboto", sans-serif;
    min-width: 35rem !important;
    transition: all 0.75s ease-in-out;
}

.App_blur__39iSo {
    -webkit-filter: blur(2.5em) !important;
            filter: blur(2.5em) !important;
    -webkit-user-select: none;
            user-select: none;
    pointer-events: none;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: hsl(210, 12%, 15%);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: hsl(210, 12%, 30%);
    border-radius: 5em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: hsl(210, 12%, 40%);
}

.App_footer__29Fsv {
    color: white;
}

