@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&family=Roboto:wght@400;700&display=swap");

html {
    min-height: 100%;
}

body {
    background: linear-gradient(90deg, #2a3035, #171a1d);
    background-size: calc(100vw) 100%;
    height: 100%;
    width: calc(100% - 10px);
    overflow-x: hidden;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}

h4 {
    font-size: 1.35em;
}

main {
    padding: 20px;
    font-family: "Roboto", sans-serif;
    min-width: 35rem !important;
    transition: all 0.75s ease-in-out;
}

.blur {
    filter: blur(2.5em) !important;
    user-select: none;
    pointer-events: none;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: hsl(210, 12%, 15%);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: hsl(210, 12%, 30%);
    border-radius: 5em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: hsl(210, 12%, 40%);
}

.footer {
    color: white;
}
