.MoreComingSoonContainer {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  background: hsla(197, 61%, 50%, 0.1);
  border-radius: 0.5em;
}

.MoreComingSoonText {
  color: hsla(197, 61%, 50%, 1);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1.25em;
}
