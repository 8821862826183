.card {
  margin: 20px auto;
  padding: 30px;
  background-color: rgba(40, 50, 55, 0.6);
  color: white;
  border-radius: 0.5em;
  max-width: 750px;
  cursor: default;
  transition: 0.2s all ease;
}

/* .card:hover {
  transition: 0.2s all ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
} */

h3 {
  letter-spacing: 0.9px;
  margin: 3px 0px;
  font-size: 2em;
}

.fancyHr {
  border: none;
  height: 2px;
  border-radius: 99em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05)
  );
}
