.card {
  margin: 15px auto;
  padding: 1.5em 2em;
  background-color: rgba(40, 50, 55, 0.6);
  color: white;
  border-radius: 0.5em;
  max-width: 750px;
  min-width: 400px;
  cursor: default;
}

.card:hover {
  transition: 0.2s all ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
}

#vibeCheckEmoji {
  display: inline-block;
  transform: rotate(-25deg) !important;
}

.fancyHr {
  border: none;
  height: 2px;
  border-radius: 99em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.05)
  );
}

.imageWrapper {
  text-align: center;
  width: 400px;
  margin: 0 auto;
}

img {
  margin: 5px;
}

#codewarsImgEmbed {
  width: 400px !important;
  height: 40px !important;
}

#psIQimageEmbed {
  width: 350px;
  border-radius: 0.5em;
  margin-top: 15px;
}

#codewarsImgEmbed,
#psIQimageEmbed {
  transition: 0.2s all ease;
  filter: brightness(0.9);
}

#codewarsImgEmbed:hover,
#psIQimageEmbed:hover {
  transition: 0.2s all ease;
  filter: brightness(1.1);
}
