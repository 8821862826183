.testimonialCard {
    display: flex;
}

.testimonialName {
    text-decoration: none;
}

.flexItem {
    padding: 12px;
}

.testimonialImg {
    height: 125px !important;
    width: 125px !important;
    border-radius: 999em;
    pointer-events: none;
}

.testimonialJobTitle {
    margin: 0;
    text-transform: uppercase;
    font-size: 1em !important;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.5);
}

.testimonialText {
    line-height: 1.5rem;
    text-align: justify;
    padding-right: 10px;
    font-size: 1.1em;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.85);
}

.testimonialText b {
    color: rgba(255, 255, 255, 1);
}

.card {
    margin: 12px auto auto;
    padding: 15px;
    background-color: hsla(200, 16%, 25%, 0.6);
    color: white;
    border-radius: 0.5em;
    max-width: 750px;
    min-width: 400px;
    cursor: default;
    text-align: left;
    opacity: 0.9;
    transition: 0.2s all ease;
}

.card:hover {
    transition: 0.2s all ease;
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.03);
}
