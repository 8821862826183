.card {
    margin: 15px auto;
    padding: 15px;
    background-color: hsla(200, 16%, 25%, 0.6);
    color: white;
    border-radius: 0.5em;
    max-width: 750px;
    min-width: 400px;
    cursor: default;
    text-align: left;
    opacity: 0.9;
    transition: 0.2s all ease;
}

h4 {
    letter-spacing: 0.9px;
    font-size: 1.75em;
    margin: 3px 0;
}

.projectThumbnail {
    height: 210px;
    float: left;
    border-radius: 0.5em;
}

.projectThumbnailLink {
    opacity: 0.8;
    display: inline-block;
    margin: 15px !important;
    padding: 0 !important;
    border: none !important;
    transform: scale(0.98);
    transition: all 0.5s ease !important;
}

.projectThumbnailLink:hover {
    opacity: 1;
    transform: scale(1.02);
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.25) !important;
}

.descriptionArea {
    padding: 0.5em 1em;
    display: inline-block;
}

.descriptionArea p {
    width: 90%;
    font-size: 1.15em;
    letter-spacing: 0.5px;
    line-height: 1.25em;
}

.techStackLogo {
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    margin: 3px;
    width: 2em;
    height: 2em;
    border-radius: 0.5em;
}

.linksArea {
    margin: 25px 0;
    line-height: 3.5em;
    white-space: nowrap;
}

.card a {
    font-family: "Raleway", sans-serif;
    color: white;
    background: none;
    font-size: 1em;
    margin: 0 5px;
    text-decoration-color: rgba(255, 255, 255, 0);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid white;
}

.card a:hover {
    transition: 0.2s all ease;
    background: rgba(255, 255, 255, 0.3);
    text-decoration-color: rgba(255, 255, 255, 1);
}
