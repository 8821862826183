.hobbyTitle {
  margin-bottom: 1rem;
}

/*ul*/
.hobbyList li::before {
  content: "⇾";
  margin-right: 1.5rem;
  opacity: 0.3;
}

.hobbyList li {
  margin: 0.75rem -2rem;
  list-style-type: none;
}
.hobbyList li a {
  color: #39a4cf;
  text-decoration-color: rgba(47, 88, 104, 0.8);
}

.hobbyList li a:hover {
  color: #23c1ff !important;
  text-decoration-color: rgba(47, 88, 104, 1);
}

.hobbyList li a:visited {
  color: #39a4cf;
}
